export default {
    username: "Username",
    phone: "Phone number",
    password: "Password",
    email: "Email",
    login: "Login",
    register: "Register",
    invitation_code: "Invitation :{code}",
    repeat_password: "Repeat password",
    please_fill_in: "Please fill in",
    home: "Home",
    price: "Market",
    chart: "Trade",
    finance: "Assets",
    mine: "My",
    deposit: "Deposit",
    withdraw_money: "Withdraw",
    contact_us: "Contact us",
    market_situation: "Market trends",
    risingList: "Top gainers",
    declineList: "Top losers",
    submitting: "Submitting",
    regularFinancialManagement: "Regular financial management",
    oneStopFinancialManagementService: "One-stop financial services",
    product: "Product",
    submit: "Submit",
    latestPrice: "Latest price",
    TodayUpsAndDowns: "Daily change",
    phoneReg: "Register with phone",
    emailReg: "Register with email",
    userReg: "User registration",
    phoneLogin: "Login with phone",
    emailLogin: "Login with email",
    userLogin: "User login",
    retrievePassword: "Recover password",
    registerAccount: "Register account ",
    tall: "High",
    low: "Low",
    min_1: "1 minute",
    min_5: "5 minutes",
    min_15: "15 minutes",
    min_30: "30 minutes",
    min_60: "60 minutes",
    hour_4: "4 hours",
    day_1: "1 day",
    buy_up: "Buy long",
    buy_down: "Buy short",
    dataIsBeingProcessedPleaseDoNotResubmit: "Data processing, please do not resubmit",
    usernameCannotBeEmpty: "Username can not be empty",
    accountCannotBeEmpty: "Account can not be empty",
    passwordCannotBeEmpty: "Password can not be empty",
    accountCannotBeDuplicate: "Account can not be duplicate",
    registerSuccess: "Registration successfull",
    loginSuccess: "Login successfull",
    logOutSuccess: "Logout successfull",
    UserDoesNotExistOrPasswordIsIncorrect: "Username is empty or password is incorrect ",
    accountCenter: "Account center",
    asset: "Assets",
    totalAccountAssetConversion: "Total account assets equivalent",
    newShareAllotment: "New share offering",
    helpCenter: "Help center",
    setup: "Settings",
    financialLending: "Financial loan",
    accountingChangeRecords: "Account change record",
    systemPrompt: "System hint",
    loginStatusHasExpiredPleaseLogInAgain: "System login status has expired, please log in again",
    selectCycle: "Select period",
    selectAmount: "Select amount",
    accountBalance: "Account balance",
    transactionPairs: "Trading pair",
    direction: "Direction",
    currentPrice: "Current price",
    orderAccount: "Order amount",
    expectedRevenue: "Expected profit",
    pleaseEnterTheAmount: "Please enter amount ",
    confirmOrderPlacement: "Confirm order ",
    insufficientBalance: "Insufficient balance ",
    toBeSettled: "Pending settlement",
    settled: "Settled",
    profitAndLoss: "Profit and loss",
    time: "Time ",
    checkoutSuccess: "Order successfull",
    invalidOrder: "Invalid order ",
    remark: "Remarks",
    tradingAndPlacingOrders: "Trading order",
    amount: "Amount",
    type: "Type",
    status: "Status",
    incomplete: "Incomplete",
    completed: "Completed",
    rejected: "Rejected",
    expenditure: "expenditure",
    income: "Income",
    quit: " Logout",
    thirdLevelDistribution: "Level 3 distribution",
    bankCardBinding: "Bank Card binding",
    orderRevenue: "Order income",
    mySuperior: "My superior",
    numberOfSubordinates: "Number of subordinates",
    totalRevenue: "Total earnings",
    distributionRevenue: "Distribution income",
    nothing: "None",
    recharge: "Recharge",
    rechargeAddress: "Recharge addresses",
    copyAddress: "Copy address",
    copySuccess: "Copy successful",
    copyError: "Copy ",
    myRecommendedLink: "My referral link",
    exchange: "Flash exchange",
    loginAndReg: "Login/Register",
    hotRoBot: "Popular robots ",
    rechargePoints: "Recharge points ",
    distributionPoints: "Distribution points",
    withdrawal: "Withdraw",
    pleaseChooseMethod: "Please select method ",
    rechargeNetwork: "Recharge network",
    depositTips: "Only support recharging to this address. Do not recharge other assets.",
    minRechargeNum: "Minimum recharge",
    maxRechargeNum: "Maximum recharge",
    rechargeNum: "Deposit amount",
    robot: "Robot",
    singleQuestionTime: "Single question time",
    submittedSuccessfully: "Submitted successfully",
    account: "Account",
    creditScore: "Credit score",
    accountInformation: "account information",
    notice: "notification",
    onlineService: "Online customer service",
    loginIP: "Login IP",
    loginTime: "Login time",
    rechargeRecords: "Recharge record",
    noContent: "No content",
    pendingReview: "Pending Review",
    pleaseCompleteTheForm: "Please complete the form",
    distributionRecords: "Distribution Records",
    gettingPointsByAnsweringQuestions: "Answer questions to earn points",
    yourOpinion: "your opinion",
    yourFuture: "Your Future",
    joinNow: "Join Now",
    operatingPrinciple: "Working principle",
    verificationCode: "Verification Code",
    buyRobot: "Buy a robot",
    confirmPurchase: "confirm purchase",
    mineRobot: "My robot",
    expirationTime: "Expire date",
    running: "In process",
    expired: "Expired",
    forgotPassword: "forget the password",
    dailyProfit: "Daily Profit",
    dailyMaximum: "Maximum number of questions per day",
    min: "Minute",
    insufficientLevelGoAndUpgradeIt: "The level is not enough, please go and upgrade it.！",
    monthlyProfit: "Monthly profit",
    workingHours: "Working time",
    hour24: "24 hours",
    gradeRules: "Level Rules",
    total: "total：",
    myOrder: "My Order",
    pendingProcessing: "Pending",
    itemInTotal: "Total items",
    flashExchangeProducts: "Flash exchange products",
    flashRefusal: "Flash exchange rejection",
    invitation: "Invitation code",
    emailCodeCantBeEmpty: "Email verification code can not be empty",
    getEmailCode: "Get email verification code",
    second: "Second",
    myTeam: "My team",
    accumulatedTeamSize: "Total team members",
    viewTeamList: "View Team List",
    accumulatedRevenue: "Cumulative income",
    accumulatedRecharge: "Cumulative recharge",
    accumulatedWithdrawal: "Cumulative withdrawal",
    rebatesForFirstGenerationMembers: "一First generation member rebate",
    rebatesForSecondGenerationMembers: "Second generation member rebate",
    thirdGenerationMemberRebate: "Third generation member rebate",
    teamDataOverview: "Team data overview",
    lastThreeGenerationsOfData: "Data from the last three generations",
    firstGenerationData: "First Generation Data",
    secondGenerationData: "Second Generation Data",
    thirdGenerationData: "Third Generation Data",
    rebateRatio: "Rebate ratio",
    teamSize: "Number of teams",
    problemCountIsMax: "The maximum number of answers has been reached",
    rechargeFlow: "Recharge flow",
    teamCommission: "Team Commission",
    inviteToEarnPoints: "Invite to earn points",
    thereNothingMoreLeft: "nothing more",
    reset: "Reset",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "ID：{id}",
    gradeInviteNum: "Invite {num} of subordinate team members",
    gradeAnswersNum: "{num} of answers to questionnaire questions",
    gradeInvitingPoints: "(Invite one person to get {num} distribution points)",
    nowGrade: "current level",
    emailCodeError:"The email code is wrong.",
    determine:"Confirm",
    cancel:"Cancel",
    copy: "Copy",
    inviteFriendsToRegister: "Invite friends to register",
    todayProfit: "Today's profit: {num}",
    taskStatus: "Task status: {text}",
    problemCountIsDayMax: "Today's maximum answer limit has been reached"

}
