<template>
  <form ref="djmForm">
    <slot></slot>
  </form>
</template>
<script>
import emitter from '../../mixins/emitter';
import mitt  from '../../mixins/mitt'
export default {
  name: 'DjmFrom',
  componentName: 'DjmFrom',
  mixins: [emitter],
  data() {
    return { fields: [] }
  },
  props: {
    model: { type: Object },
    rules: { type: Object }
  },
  provide() {
    return { djmForm: this }
  },
  methods: {
    resetFields() {
      this.fields.forEach(field => field.resetField())
    },
    validate(cb) {
      mitt.emit('djm-form-valid', this);
      return new Promise(resolve => {
        let valid = true, count = 0;
        this.fields.forEach(field => {
          field.validate('', error => {
            if (error) valid = false;
            if (++count === this.fields.length) {
              resolve(valid);
              if (typeof cb === 'function') cb(valid);
            }
          })
        })
      })
    }
  },
  created() {
    mitt.on('djm-form-addField',field => {
      if (field) this.fields.push(field);
    })
    mitt.on('djm-form-removeField', field => {
      if (field.prop) this.fields.splice(this.fields.indexOf(field), 1);
    })
  }
}
</script>
<style scoped lang="scss">

</style>
