<template>
  <input class="form-control" ref="input" :type="type" :value="currentValue" @input="handleInput" @blur="handleBlur" @change="handleChange">
</template>

<script>
import emitter from '../../mixins/emitter';
import mitt from "../../mixins/mitt";
export default {
  name: 'DjmInput',

  componentName: 'DjmInput',
  mixins: [emitter],
  props: {
    type: { type: String, default: 'text'},
    value: { type: String, default: ''}
  },
  watch: {
    value(value) {
      this.currentValue = value
    }
  },
  data() {
    return { currentValue: this.value, id: this.label, isShowMes: false }
  },
  mounted () {
    if (this.$parent.labelFor) this.$refs.input.id = this.$parent.labelFor;
  },
  methods: {
    handleInput(e) {
      const value = e.target.value;
      this.currentValue = value;
      this.$emit('input', value);
    },
    handleChange(){
      this.dispatch('DjmFromItem', 'djm-form-change', [this.value]);
    },
    handleBlur() {
      this.dispatch('DjmFromItem', 'djm-form-blur', [this.value]);
      let input = this.$refs.input;
      this.isShowMes = this.$parent.isShowMes
      input.classList.remove('is-valid');
      input.classList.remove('is-invalid');
      if(this.isShowMes){
        input.classList.add('is-invalid');
      } else {
        input.classList.add('is-valid');
      }
    }
  },
  created() {
    mitt.on('djm-form-valid',()=>{
      this.handleBlur()
    });
  }

}
</script>
