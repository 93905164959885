import DjmFrom from "./packages/form/index.vue"
import DjmFromItem from "./packages/form-item/index.vue"
import DjmInput from "./packages/input/index.vue"




function install(Vue,opts={}){
    Vue.component("djmFrom",DjmFrom)
    Vue.component("djmFromItem",DjmFromItem)
    Vue.component("djmInput",DjmInput)
}
export default {
    install
}
