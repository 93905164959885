export default {
    username: "Tên người dùng",
    phone: "Số điện thoại",
    password: "Mật khẩu",
    email: "Email",
    login: "Đăng nhập",
    register: "Đăng ký",
    invitation_code: "Mã mời: {code}",
    repeat_password: "Nhập lại mật khẩu",
    please_fill_in: "Vui lòng điền vào",
    home: "Trang chủ",
    price: "Giá điểm",
    chart: "Biểu đồ",
    finance: "Tài sản",
    mine: "Của tôi",
    deposit: "Nạp tiền",
    withdraw_money: "Rút tiền",
    contact_us: "Liên hệ với chúng tôi",
    market_situation: "Tình hình thị trường",
    risingList: "Danh sách tăng",
    declineList: "Danh sách giảm",
    submitting: "Đang gửi...",
    regularFinancialManagement: "Quản lý tài chính định kỳ",
    oneStopFinancialManagementService: "Dịch vụ quản lý tài chính một điểm",
    product: "Sản phẩm",
    submit: "Gửi",
    latestPrice: "Giá mới nhất",
    TodayUpsAndDowns: "Biến động hôm nay",
    phoneReg: "Đăng ký bằng điện thoại",
    emailReg: "Đăng ký bằng email",
    userReg: "Đăng ký người dùng",
    phoneLogin: "Đăng nhập bằng điện thoại",
    emailLogin: "Đăng nhập bằng email",
    userLogin: "Đăng nhập người dùng",
    retrievePassword: "Lấy lại mật khẩu",
    registerAccount: "Đăng ký tài khoản",
    tall: "Cao",
    low: "Thấp",
    min_1: "1 phút",
    min_5: "5 phút",
    min_15: "15 phút",
    min_30: "30 phút",
    min_60: "60 phút",
    hour_4: "4 giờ",
    day_1: "1 ngày",
    buy_up: "Mua tăng",
    buy_down: "Mua giảm",
    dataIsBeingProcessedPleaseDoNotResubmit: "Dữ liệu đang được xử lý. Vui lòng không gửi lại",
    usernameCannotBeEmpty: "Tên người dùng không được để trống",
    accountCannotBeEmpty: "Tài khoản không được để trống",
    passwordCannotBeEmpty: "Mật khẩu không được để trống",
    inviterCodeCannotBeEmpty: "Mã mời không được để trống",
    accountCannotBeDuplicate: "Tài khoản không được trùng lặp",
    registerSuccess: "Đăng ký thành công",
    loginSuccess: "Đăng nhập thành công",
    logOutSuccess: "Đăng xuất thành công",
    UserDoesNotExistOrPasswordIsIncorrect: "Người dùng không tồn tại hoặc mật khẩu không chính xác",
    accountCenter: "Trung tâm tài khoản",
    asset: "Tài sản",
    totalAccountAssetConversion: "Tổng tài sản chuyển đổi",
    newShareAllotment: "Phân bổ cổ phiếu mới",
    helpCenter: "Trung tâm trợ giúp",
    setup: "Cài đặt",
    financialLending: "Cho vay tài chính",
    accountingChangeRecords: "Hồ sơ thay đổi kế toán",
    systemPrompt: "Thông báo hệ thống",
    loginStatusHasExpiredPleaseLogInAgain: "Trạng thái đăng nhập đã hết hạn. Vui lòng đăng nhập lại",
    selectCycle: "Chọn chu kỳ",
    selectAmount: "Chọn số tiền",
    accountBalance: "Số dư tài khoản: {num} $",
    transactionPairs: "Cặp giao dịch",
    direction: "Hướng",
    currentPrice: "Giá hiện tại",
    orderAccount: "Số tiền đặt hàng",
    expectedRevenue: "Doanh thu dự kiến",
    pleaseEnterTheAmount: "Vui lòng nhập số tiền",
    confirmOrderPlacement: "Xác nhận đặt hàng",
    insufficientBalance: "Số dư không đủ",
    toBeSettled: "Chưa giải quyết",
    settled: "Đã giải quyết",
    profitAndLoss: "Lợi nhuận và lỗ",
    time: "Thời gian",
    checkoutSuccess: "Đặt hàng thành công",
    invalidOrder: "Đơn hàng không hợp lệ",
    remark: "Ghi chú:",
    tradingAndPlacingOrders: "Giao dịch và đặt hàng",
    amount: "Số lượng",
    type: "Loại",
    status: "Trạng thái",
    incomplete: "Chưa hoàn thành",
    completed: "Đã hoàn thành",
    rejected: "Đã từ chối",
    expenditure: "Chi tiêu",
    income: "Thu nhập",
    quit: "Thoát",
    thirdLevelDistribution: "Phân phối cấp ba",
    bankCardBinding: "Liên kết thẻ ngân hàng",
    orderRevenue: "Doanh thu đơn hàng",
    mySuperior: "Người cấp trên của tôi",
    numberOfSubordinates: "Số lượng cấp dưới",
    totalRevenue: "Tổng thu nhập",
    distributionRevenue: "Thu nhập phân phối",
    nothing: "Không có",
    recharge: "Nạp tiền",
    rechargeAddress: "Địa chỉ nạp tiền",
    copyAddress: "Sao chép địa chỉ",
    copySuccess: "Sao chép thành công",
    copyError: "Lỗi sao chép",
    myRecommendedLink: "Đường dẫn giới thiệu của tôi",
    exchange: "Trao đổi",
    loginAndReg: "Đăng nhập/Đăng ký",
    hotRoBot: "Robot nổi bật",
    rechargePoints: "Nạp điểm",
    distributionPoints: "Phân phối điểm",
    withdrawal: "Rút tiền",
    pleaseChooseMethod: "Vui lòng chọn phương pháp",
    rechargeNetwork: "Mạng nạp tiền",
    depositTips: "Chỉ hỗ trợ nạp {name} vào địa chỉ này, vui lòng không nạp tài sản khác.",
    minRechargeNum: "Nạp tối thiểu: {num}",
    maxRechargeNum: "Nạp tối đa: {num}",
    rechargeNum: "Số tiền nạp",
    robot: "Robot",
    singleQuestionTime: "Thời gian mỗi câu hỏi",
    submittedSuccessfully: "Gửi thành công",
    account: "Tài khoản",
    creditScore: "Điểm tín dụng",
    accountInformation: "Thông tin tài khoản",
    notice: "Thông báo",
    onlineService: "Dịch vụ trực tuyến",
    loginIP: "Địa chỉ IP đăng nhập",
    loginTime: "Thời gian đăng nhập",
    rechargeRecords: "Lịch sử nạp tiền",
    noContent: "Không có nội dung",
    pendingReview: "Đang chờ duyệt",
    pleaseCompleteTheForm: "Vui lòng hoàn thành biểu mẫu",
    distributionRecords: "Lịch sử phân phối",
    gettingPointsByAnsweringQuestions: "Nhận điểm bằng cách trả lời câu hỏi",
    yourOpinion: "Ý kiến của bạn",
    yourFuture: "Tương lai của bạn",
    joinNow: "Tham gia ngay",
    operatingPrinciple: "Nguyên lý hoạt động",
    verificationCode: "Mã xác nhận",
    buyRobot: "Mua robot",
    confirmPurchase: "Xác nhận mua hàng",
    mineRobot: "Robot của tôi",
    expirationTime: "Hết hạn",
    running: "Đang chạy",
    expired: "Đã hết hạn",
    forgotPassword: "Quên mật khẩu",
    dailyProfit: "Lợi nhuận hàng ngày",
    dailyMaximum: "Số câu hỏi tối đa mỗi ngày",
    min: "Phút",
    insufficientLevelGoAndUpgradeIt: "Cấp độ không đủ, hãy nâng cấp nó!",
    monthlyProfit: "Lợi nhuận hàng tháng",
    workingHours: "Giờ làm việc",
    hour24: "24 giờ",
    gradeRules: "Quy tắc cấp bậc",
    total: "Tổng cộng:",
    myOrder: "Đơn hàng của tôi",
    pendingProcessing: "Đang xử lý",
    itemInTotal: "Tổng cộng {num} mặt hàng",
    flashExchangeProducts: "Sản phẩm trao đổi nhanh",
    flashRefusal: "Từ chối trao đổi nhanh",
    invitation: "Lời mời",
    emailCodeCantBeEmpty: "Mã xác nhận email không được để trống",
    getEmailCode: "Nhận mã xác nhận email",
    second: "Giây",
    myTeam: "Đội của tôi",
    accumulatedTeamSize: "Tổng số thành viên trong đội",
    viewTeamList: "Xem danh sách đội",
    accumulatedRevenue: "Tổng doanh thu",
    accumulatedRecharge: "Tổng số tiền nạp",
    accumulatedWithdrawal: "Tổng số tiền rút",
    rebatesForFirstGenerationMembers: "Hoàn trả cho thành viên thế hệ 1",
    rebatesForSecondGenerationMembers: "Hoàn trả cho thành viên thế hệ 2",
    thirdGenerationMemberRebate: "Hoàn trả cho thành viên thế hệ 3",
    teamDataOverview: "Tổng quan dữ liệu đội",
    lastThreeGenerationsOfData: "Dữ liệu ba thế hệ gần đây",
    firstGenerationData: "Dữ liệu thế hệ 1",
    secondGenerationData: "Dữ liệu thế hệ 2",
    thirdGenerationData: "Dữ liệu thế hệ 3",
    rebateRatio: "Tỷ lệ hoàn trả",
    teamSize: "Kích thước đội",
    problemCountIsMax: "Số câu hỏi đã đạt giới hạn tối đa",
    rechargeFlow: "Dòng tiền nạp",
    teamCommission: "Hoa hồng nhóm",
    inviteToEarnPoints: "Mời để kiếm điểm",
    thereNothingMoreLeft: "Không còn gì nữa",
    reset: "Đặt lại",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "ID: {id}",
    gradeInviteNum: "Mời {num} thành viên đội cấp dưới",
    gradeAnswersNum: "Trả lời {num} câu hỏi khảo sát",
    gradeInvitingPoints: "(Mời một người nhận {num} điểm phân phối)",
    nowGrade: "Cấp độ hiện tại",
    emailCodeError:"Mã xác nhận email sai",
    determine:"Xác nhận",
    cancel:"Hủy",
    copy: "Sao chép",
    inviteFriendsToRegister: "Mời bạn bè đăng ký",
    todayProfit: "Lợi nhuận hôm nay: {num}",
    taskStatus: "Trạng thái nhiệm vụ: {text}",
    problemCountIsDayMax: "Đã đạt giới hạn số lượng câu hỏi hôm nay"

};
