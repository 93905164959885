import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n';
import DjmUI from 'djm-ui';

// 2. 引入组件样式
import './styles/font.css'
import './styles/theme.scss'
import './styles/animation.scss'
import './styles/navbar.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.scss'

Vue.config.productionTip = false

Vue.use(DjmUI)

new Vue({
    el: '#app',
    router,
    i18n,
    DjmUI,
    render: h => h(App)
})
