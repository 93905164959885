<template>
  <div class="form-group mb-25 mb-3">
    <label class="form-label"  :for="labelFor" v-if="label" :class="{'label-required': isRequired}">{{label}}</label>
    <slot></slot>
    <div v-if="isShowMes" class="message">{{message}}</div>
  </div>
</template>
<script>
import AsyncValidator from 'async-validator';
import mitt  from '../../mixins/mitt'
import objectAssign from '../../utils/merge';
export default {
  name: 'DjmFromItem',

  componentName: 'DjmFromItem',

  provide() {
    return {
      djmFormItem: this
    };
  },

  inject: [ 'djmForm' ],

  props: {
    label: { type: String, default: '' },
    prop: { type: String }
  },
  data() {
    return {
      isRequired: false, isShowMes: false, message: '', labelFor: 'input' + new Date().valueOf()
    }
  },
  mounted() {
    if (this.prop) {
      mitt.emit('djm-form-addField', this);
      // 设置初始值
      this.initialValue = this.fieldValue;
      this.setRules();
    }
  },
  // 组件销毁前，将实例从 Form 的缓存中移除
  beforeUnmount () {
    mitt.emit('djm-form-removeField', this);
  },
  computed: {
    fieldValue() {
      return this.djmForm.model[this.prop]
    }
  },
  methods: {
    setRules() {
      let rules = this.getRules();
      if (rules.length) {
        rules.forEach(rule => {
          if (rule.required !== undefined) this.isRequired = rule.required
        });
      }
      this.$on('djm-form-blur', this.onFieldBlur);
      this.$on('djm-form-change', this.onFieldChange);
    },
    getRules() {
      let formRules = this.djmForm.rules;
      formRules = formRules ? formRules[this.prop] : [];

      return formRules;
    },
    // 过滤出符合要求的 rule 规则
    getFilteredRule (trigger) {
      const rules = this.getRules();

      return rules.filter(rule => {
        if (!rule.trigger || trigger === '') return true;
        if (Array.isArray(rule.trigger)) {
          return rule.trigger.indexOf(trigger) > -1;
        } else {
          return rule.trigger === trigger;
        }
      }).map(rule => objectAssign({}, rule));
    },
    /**
     * 校验表单数据
     * @param trigger 触发校验类型
     * @param callback 回调函数
     */
    validate(trigger, cb) {
      let rules = this.getFilteredRule(trigger);
      if(!rules || rules.length === 0) return true;
      // 使用 async-validator
      const validator = new AsyncValidator({ [this.prop]: rules });
      let model = {[this.prop]: this.fieldValue};
      validator.validate(model, { firstFields: true }, errors => {
        this.isShowMes = errors ? true : false;
        this.message = errors ? errors[0].message : '';
        if (cb) cb(this.message);
      })
    },
    resetField () {
      this.message = '';
      this.djmForm.model[this.prop] = this.initialValue;
    },
    onFieldBlur() {
      this.validate('blur');
    },
    onFieldChange() {
      this.validate('change');
    }
  }
}
</script>
<style scoped lang="scss">
.label-required:before {
  content: '*';
  color: red;
}
.message {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}
</style>
