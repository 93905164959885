<template>
<!--  <Loading></Loading>-->
  <router-view/>
</template>


<script>

import Loading from '@/components/Loading'

export default {
  name: 'App',
  components: {
    Loading
  },
  created() {

  },
  methods:{

  }
}
</script>

<style>

</style>
