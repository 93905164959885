export default {
    username: "사용자 이름",
    phone: "휴대폰 번호",
    password: "비밀번호",
    email: "이메일",
    login: "로그인",
    register: "회원 가입",
    invitation_code: "초대 코드: {code}",
    repeat_password: "비밀번호 재입력",
    please_fill_in: "입력해 주세요",
    home: "홈",
    price: "포인트 가격",
    chart: "차트",
    finance: "자산",
    mine: "내 정보",
    deposit: "입금",
    withdraw_money: "출금",
    contact_us: "문의하기",
    market_situation: "시장 상황",
    risingList: "상승 리스트",
    declineList: "하락 리스트",
    submitting: "제출 중...",
    regularFinancialManagement: "정기 금융 관리",
    oneStopFinancialManagementService: "통합 금융 관리 서비스",
    product: "제품",
    submit: "제출",
    latestPrice: "최신 가격",
    TodayUpsAndDowns: "오늘의 상승과 하락",
    phoneReg: "휴대폰 회원 가입",
    emailReg: "이메일 회원 가입",
    userReg: "사용자 회원 가입",
    phoneLogin: "휴대폰 로그인",
    emailLogin: "이메일 로그인",
    userLogin: "사용자 로그인",
    retrievePassword: "비밀번호 찾기",
    registerAccount: "계정 등록",
    tall: "높음",
    low: "낮음",
    min_1: "1분",
    min_5: "5분",
    min_15: "15분",
    min_30: "30분",
    min_60: "60분",
    hour_4: "4시간",
    day_1: "1일",
    buy_up: "상승 매수",
    buy_down: "하락 매수",
    dataIsBeingProcessedPleaseDoNotResubmit: "데이터 처리 중입니다. 다시 제출하지 마세요.",
    usernameCannotBeEmpty: "사용자 이름을 입력하세요.",
    accountCannotBeEmpty: "계정을 입력하세요.",
    passwordCannotBeEmpty: "비밀번호를 입력하세요.",
    inviterCodeCannotBeEmpty: "초대 코드를 입력하세요.",
    accountCannotBeDuplicate: "중복된 계정입니다.",
    registerSuccess: "회원 가입 성공",
    loginSuccess: "로그인 성공",
    logOutSuccess: "로그아웃 성공",
    UserDoesNotExistOrPasswordIsIncorrect: "사용자가 존재하지 않거나 비밀번호가 잘못되었습니다.",
    accountCenter: "계정 센터",
    asset: "자산",
    totalAccountAssetConversion: "총 계정 자산 변환",
    newShareAllotment: "신규 주식 할당",
    helpCenter: "도움말 센터",
    setup: "설정",
    financialLending: "금융 대출",
    accountingChangeRecords: "계정 변경 기록",
    systemPrompt: "시스템 메시지",
    loginStatusHasExpiredPleaseLogInAgain: "로그인 상태가 만료되었습니다. 다시 로그인하세요.",
    selectCycle: "주기 선택",
    selectAmount: "금액 선택",
    accountBalance: "계정 잔액: {num} $",
    transactionPairs: "거래 쌍",
    direction: "방향",
    currentPrice: "현재 가격",
    orderAccount: "주문 금액",
    expectedRevenue: "예상 수익",
    pleaseEnterTheAmount: "금액을 입력하세요.",
    confirmOrderPlacement: "주문 확인",
    insufficientBalance: "잔액 부족",
    toBeSettled: "결제 예정",
    settled: "결제 완료",
    profitAndLoss: "이익과 손실",
    time: "시간",
    checkoutSuccess: "주문 성공",
    invalidOrder: "유효하지 않은 주문",
    remark: "비고:",
    tradingAndPlacingOrders: "거래 및 주문",
    amount: "수량",
    type: "유형",
    status: "상태",
    incomplete: "미완료",
    completed: "완료",
    rejected: "거부됨",
    expenditure: "지출",
    income: "수입",
    quit: "종료",
    thirdLevelDistribution: "3단계 분배",
    bankCardBinding: "은행 카드 바인딩",
    orderRevenue: "주문 수익",
    mySuperior: "나의 상위",
    numberOfSubordinates: "하위 직원 수",
    totalRevenue: "총 수익",
    distributionRevenue: "분배 수익",
    nothing: "없음",
    recharge: "충전",
    rechargeAddress: "충전 주소",
    copyAddress: "주소 복사",
    copySuccess: "복사 성공",
    copyError: "복사 실패",
    myRecommendedLink: "내 추천 링크",
    exchange: "빠른 교환",
    loginAndReg: "로그인/회원 가입",
    hotRoBot: "인기 로봇",
    rechargePoints: "포인트 충전",
    distributionPoints: "포인트 분배",
    withdrawal: "인출",
    pleaseChooseMethod: "방법 선택",
    rechargeNetwork: "네트워크 충전",
    depositTips: "{name}에만 충전을 지원합니다. 다른 자산으로 충전하지 마세요.",
    minRechargeNum: "최소 충전량: {num}",
    maxRechargeNum: "최대 충전량: {num}",
    rechargeNum: "충전 수량",
    robot: "로봇",
    singleQuestionTime: "단일 문제 시간",
    submittedSuccessfully: "성공적으로 제출되었습니다.",
    account: "계정",
    creditScore: "신용 점수",
    accountInformation: "계정 정보",
    notice: "공지",
    onlineService: "온라인 서비스",
    loginIP: "로그인 IP",
    loginTime: "로그인 시간",
    rechargeRecords: "충전 기록",
    noContent: "내용 없음",
    pendingReview: "심사 대기 중",
    pleaseCompleteTheForm: "양식을 완성하세요.",
    distributionRecords: "분배 기록",
    gettingPointsByAnsweringQuestions: "질문에 답하여 포인트를 얻기",
    yourOpinion: "당신의 의견",
    yourFuture: "당신의 미래",
    joinNow: "지금 가입하세요",
    operatingPrinciple: "운영 원칙",
    verificationCode: "인증 코드",
    buyRobot: "로봇 구매",
    confirmPurchase: "구매 확인",
    mineRobot: "내 로봇",
    expirationTime: "만료 시간",
    running: "운영 중",
    expired: "만료됨",
    forgotPassword: "비밀번호 잊어버림",
    dailyProfit: "일일 수익",
    dailyMaximum: "일일 최대 문제 수",
    min: "분",
    insufficientLevelGoAndUpgradeIt: "레벨이 부족하여 업그레이드하세요!",
    monthlyProfit: "월간 수익",
    workingHours: "근무 시간",
    hour24: "24시간",
    gradeRules: "등급 규칙",
    total: "총계:",
    myOrder: "나의 주문",
    pendingProcessing: "처리 대기 중",
    itemInTotal: "총 {num} 개의 상품",
    flashExchangeProducts: "빠른 교환 상품",
    flashRefusal: "빠른 교환 거부",
    invitation: "초대 코드",
    emailCodeCantBeEmpty: "이메일 코드를 입력하세요.",
    getEmailCode: "이메일 코드 받기",
    second: "초",
    myTeam: "나의 팀",
    accumulatedTeamSize: "누적 팀 규모",
    viewTeamList: "팀 목록 보기",
    accumulatedRevenue: "누적 수익",
    accumulatedRecharge: "누적 충전",
    accumulatedWithdrawal: "누적 출금",
    rebatesForFirstGenerationMembers: "1세대 회원 리베이트",
    rebatesForSecondGenerationMembers: "2세대 회원 리베이트",
    thirdGenerationMemberRebate: "3세대 회원 리베이트",
    teamDataOverview: "팀 데이터 개요",
    lastThreeGenerationsOfData: "최근 세 세대 데이터",
    firstGenerationData: "1세대 데이터",
    secondGenerationData: "2세대 데이터",
    thirdGenerationData: "3세대 데이터",
    rebateRatio: "리베이트 비율",
    teamSize: "팀 규모",
    problemCountIsMax: "최대 질문 수에 도달했습니다.",
    rechargeFlow: "충전 흐름",
    teamCommission: "팀 커미션",
    inviteToEarnPoints: "포인트를 얻기 위해 초대",
    thereNothingMoreLeft: "더 이상 없습니다.",
    reset: "초기화",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "ID: {id}",
    gradeInviteNum: "하위 팀원 {num} 명 초대",
    gradeAnswersNum: "설문 {num} 개 답변",
    gradeInvitingPoints: "(한 명 초대 시 {num} 포인트 받기)",
    nowGrade: "현재 등급",
    emailCodeError:"인증 코드 오류",
    determine:"확인",
    cancel:"취소",
    copy: "복사",
    inviteFriendsToRegister: "친구 초대하여 등록하기",
    todayProfit: "오늘 수익: {num}",
    taskStatus: "작업 상태: {text}",
    problemCountIsDayMax: "오늘의 답변 제한에 도달했습니다"

};
