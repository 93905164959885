export default {
    username: "ユーザー名",
    phone: "電話番号",
    password: "パスワード",
    email: "メールアドレス",
    login: "ログイン",
    register: "登録",
    invitation_code: "招待コード：{code}",
    repeat_password: "パスワードを再入力",
    please_fill_in: "入力してください",
    home: "ホーム",
    price: "ポイント価格",
    chart: "チャート",
    finance: "資産",
    mine: "マイページ",
    deposit: "入金",
    withdraw_money: "出金",
    contact_us: "お問い合わせ",
    market_situation: "市場状況",
    risingList: "上昇リスト",
    declineList: "下落リスト",
    submitting: "送信中...",
    regularFinancialManagement: "定期的な資産管理",
    oneStopFinancialManagementService: "ワンストップ資産管理サービス",
    product: "製品",
    submit: "送信",
    latestPrice: "最新価格",
    TodayUpsAndDowns: "今日の上下",
    phoneReg: "電話で登録",
    emailReg: "メールで登録",
    userReg: "ユーザー登録",
    phoneLogin: "電話でログイン",
    emailLogin: "メールでログイン",
    userLogin: "ユーザーログイン",
    retrievePassword: "パスワードを再設定",
    registerAccount: "アカウント登録",
    tall: "高",
    low: "低",
    min_1: "1分",
    min_5: "5分",
    min_15: "15分",
    min_30: "30分",
    min_60: "60分",
    hour_4: "4時間",
    day_1: "1日",
    buy_up: "買い上げ",
    buy_down: "売り下げ",
    dataIsBeingProcessedPleaseDoNotResubmit: "データ処理中です。再送信しないでください。",
    usernameCannotBeEmpty: "ユーザー名は空にできません",
    accountCannotBeEmpty: "アカウントは空にできません",
    passwordCannotBeEmpty: "パスワードは空にできません",
    inviterCodeCannotBeEmpty: "招待コードは空にできません",
    accountCannotBeDuplicate: "アカウントが重複しています",
    registerSuccess: "登録が成功しました",
    loginSuccess: "ログインが成功しました",
    logOutSuccess: "ログアウトが成功しました",
    UserDoesNotExistOrPasswordIsIncorrect: "ユーザーが存在しないか、パスワードが間違っています",
    accountCenter: "アカウントセンター",
    asset: "資産",
    totalAccountAssetConversion: "総資産変換",
    newShareAllotment: "新株配当",
    helpCenter: "ヘルプセンター",
    setup: "設定",
    financialLending: "金融融資",
    accountingChangeRecords: "会計変更記録",
    systemPrompt: "システムメッセージ",
    loginStatusHasExpiredPleaseLogInAgain: "ログイン状態の有効期限が切れました。もう一度ログインしてください",
    selectCycle: "サイクルを選択",
    selectAmount: "金額を選択",
    accountBalance: "アカウント残高：{num} $",
    transactionPairs: "取引ペア",
    direction: "方向",
    currentPrice: "現在価格",
    orderAccount: "注文金額",
    expectedRevenue: "予想収益",
    pleaseEnterTheAmount: "金額を入力してください",
    confirmOrderPlacement: "注文確認",
    insufficientBalance: "残高が不足しています",
    toBeSettled: "未決済",
    settled: "決済済み",
    profitAndLoss: "利益と損失",
    time: "時間",
    checkoutSuccess: "注文が成功しました",
    invalidOrder: "無効な注文",
    remark: "備考：",
    tradingAndPlacingOrders: "取引と注文",
    amount: "数量",
    type: "タイプ",
    status: "ステータス",
    incomplete: "未完了",
    completed: "完了",
    rejected: "拒否されました",
    expenditure: "支出",
    income: "収入",
    quit: "終了",
    thirdLevelDistribution: "3レベル分配",
    bankCardBinding: "銀行カードのバインディング",
    orderRevenue: "注文収益",
    mySuperior: "上位",
    numberOfSubordinates: "下位の数",
    totalRevenue: "総収益",
    distributionRevenue: "分配収益",
    nothing: "なし",
    recharge: "チャージ",
    rechargeAddress: "チャージアドレス",
    copyAddress: "アドレスをコピー",
    copySuccess: "コピー成功",
    copyError: "コピー失敗",
    myRecommendedLink: "私のおすすめリンク",
    exchange: "交換",
    loginAndReg: "ログイン/登録",
    hotRoBot: "人気ロボット",
    rechargePoints: "ポイントチャージ",
    distributionPoints: "ポイント分配",
    withdrawal: "引き出し",
    pleaseChooseMethod: "方法を選択してください",
    rechargeNetwork: "チャージネットワーク",
    depositTips: "このアドレスへの{num}のみをサポートしています。他のアセットをチャージしないでください。",
    minRechargeNum: "最小チャージ：{num}",
    maxRechargeNum: "最大チャージ：{num}",
    rechargeNum: "チャージ数量",
    robot: "ロボット",
    singleQuestionTime: "1つの質問にかかる時間",
    submittedSuccessfully: "送信成功",
    account: "アカウント",
    creditScore: "信用スコア",
    accountInformation: "アカウント情報",
    notice: "お知らせ",
    onlineService: "オンラインサービス",
    loginIP: "ログインIP",
    loginTime: "ログイン時間",
    rechargeRecords: "チャージ履歴",
    noContent: "内容がありません",
    pendingReview: "審査中",
    pleaseCompleteTheForm: "フォームを完了してください",
    distributionRecords: "分配記録",
    gettingPointsByAnsweringQuestions: "質問に答えてポイントを取得",
    yourOpinion: "あなたの意見",
    yourFuture: "あなたの未来",
    joinNow: "今すぐ参加",
    operatingPrinciple: "運営原則",
    verificationCode: "認証コード",
    buyRobot: "ロボット購入",
    confirmPurchase: "購入確認",
    mineRobot: "私のロボット",
    expirationTime: "有効期限",
    running: "実行中",
    expired: "期限切れ",
    forgotPassword: "パスワードを忘れた",
    dailyProfit: "日次利益",
    dailyMaximum: "1日の最大質問数",
    min: "分",
    insufficientLevelGoAndUpgradeIt: "レベルが不足しています。アップグレードしてください！",
    monthlyProfit: "月次利益",
    workingHours: "作業時間",
    hour24: "24時間",
    gradeRules: "グレードルール",
    total: "合計：",
    myOrder: "私の注文",
    pendingProcessing: "処理中",
    itemInTotal: "合計{num}アイテム",
    flashExchangeProducts: "フラッシュ交換商品",
    flashRefusal: "フラッシュ交換拒否",
    invitation: "招待",
    emailCodeCantBeEmpty: "メールコードは空にできません",
    getEmailCode: "メールコードを取得",
    second: "秒",
    myTeam: "私のチーム",
    accumulatedTeamSize: "累積チームサイズ",
    viewTeamList: "チームリストを表示",
    accumulatedRevenue: "累積収益",
    accumulatedRecharge: "累積チャージ",
    accumulatedWithdrawal: "累積引き出し",
    rebatesForFirstGenerationMembers: "第1世代メンバーのリベート",
    rebatesForSecondGenerationMembers: "第2世代メンバーのリベート",
    thirdGenerationMemberRebate: "第3世代メンバーのリベート",
    teamDataOverview: "チームデータの概要",
    lastThreeGenerationsOfData: "最後の3世代のデータ",
    firstGenerationData: "第1世代のデータ",
    secondGenerationData: "第2世代のデータ",
    thirdGenerationData: "第3世代のデータ",
    rebateRatio: "リベート率",
    teamSize: "チームサイズ",
    problemCountIsMax: "問題の最大数に達しました",
    rechargeFlow: "チャージフロー",
    teamCommission: "チーム手数料",
    inviteToEarnPoints: "ポイントを稼ぐための招待",
    thereNothingMoreLeft: "これ以上ありません",
    reset: "リセット",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "ID: {id}",
    gradeInviteNum: "{num}人の下位チームメンバーを招待する",
    gradeAnswersNum: "アンケートの質問{num}を回答する",
    gradeInvitingPoints: "(1人を招待すると、{num}ポイントの分配ポイントを得る)",
    nowGrade: "現在の等級",
    emailCodeError:"メールの認証コードが間違っています。",
    determine:"確認",
    cancel:"キャンセル",
    copy: "コピー",
    inviteFriendsToRegister: "友達を招待して登録する",
    todayProfit: "今日の利益: {num}",
    taskStatus: "タスクの状態: {text}",
    problemCountIsDayMax: "本日の回答上限に達しました"

};
