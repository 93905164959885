export default {
    username:"Nome de usuário ",
    phone:"Número de celular",
    password:"Senha",
    email:"e-mail",
    login:"Login",
    register:"Cadastro",
    invitation_code:"Código de convite: {code}",
    repeat_password:"Repetir senha",
    please_fill_in:"Por favor preencha",
    home:"Pagina Inicial",
    price:"preço",
    chart:"Transação",
    finance:"Ativos",
    mine:"meu",
    deposit:"Depósito",
    withdraw_money:"Saque",
    contact_us:"Entre em contato conosco",
    market_situation:"Situação do mercado",
    risingList:"Lista de ganhadores",
    declineList:"Lista de perdedores",
    submitting:"Enviando...",
    regularFinancialManagement:"Gestão financeira regular",
    oneStopFinancialManagementService:"Serviço de gerenciamento financeiro completo",
    product:"Produto",
    submit:"Enviar",
    latestPrice:"Último preço",
    TodayUpsAndDowns:"Os altos e baixos de hoje",
    phoneReg:"Cadastro no celular",
    emailReg:"Cadastro no email",
    userReg:"Cadastro de usuário",
    phoneLogin:"Login no celular",
    emailLogin:"Login no email",
    userLogin:"Login do usuário ",
    retrievePassword:"Recuperar a senha ",
    registerAccount:"Cadastrar a conta ",
    tall:"Alto",
    low:"Baixo",
    min_1:"1minuto",
    min_5:"5minutos",
    min_15:"15minutos",
    min_30:"30minutos",
    min_60:"60minutos",
    hour_4:"4horas ",
    day_1:"1dia ",
    buy_up:"comprar longo",
    buy_down:"comprar curto",
    dataIsBeingProcessedPleaseDoNotResubmit:"Dados esta em processamento, por favor não reenvie ",
    usernameCannotBeEmpty:"O nome de usuário não pode ficar vazio",
    accountCannotBeEmpty:"A conta não pode estar vazia",
    passwordCannotBeEmpty:"A senha não pode ficar vazia",
    inviterCodeCannotBeEmpty:"O código do convite não pode estar vazio",
    accountCannotBeDuplicate:"A conta não pode ser duplicada",
    registerSuccess:"Cadastro realizado com sucesso",
    loginSuccess:"Login com sucesso",
    logOutSuccess:"Logout com sucesso ",
    UserDoesNotExistOrPasswordIsIncorrect:"O nome de usuário está vazio ou a senha está incorreta",
    accountCenter:"Centro de Contas",
    asset:"Ativo",
    totalAccountAssetConversion:"Conversão total de ativos da conta",
    newShareAllotment:"Nova Atribuição de Ações",
    helpCenter:"Central de Ajuda",
    setup:"Configuração",
    financialLending:"Empréstimo Financeiro",
    accountingChangeRecords:"registros de alteração de conta",
    systemPrompt:"Dica do Sistema",
    loginStatusHasExpiredPleaseLogInAgain:"O login expirou, faça login novamente",
    selectCycle:"Selecionar ciclo",
    selectAmount:"Selecione o valor",
    accountBalance:"Saldo da conta: {num} $",
    transactionPairs:"Pares de Transações",
    direction:"Direção",
    currentPrice:"Preço atual",
    orderAccount:"Valor do pedido",
    expectedRevenue:"receita esperada",
    pleaseEnterTheAmount:"Por favor, insira o valor",
    confirmOrderPlacement:"Confirmar pedido",
    insufficientBalance:"Saldo insuficiente",
    toBeSettled:"A ser liquidado",
    settled:"Liquidado",
    profitAndLoss:"Lucro e Perda",
    time:"Tempo",
    checkoutSuccess :"Pedido realizado com sucesso",
    invalidOrder :"Pedido inválido",
    remark :"Observação：",
    tradingAndPlacingOrders :"Ordens de negociação",
    amount :"Quantidade",
    type :"Tipo",
    status :"Status",
    incomplete :"Não concluído",
    completed :"Concluído",
    rejected :"Rejeitado",
    expenditure :"Despesa",
    income :"Renda",
    quit :"Sair ",
    thirdLevelDistribution :"Distribuição de terceiro nível",
    bankCardBinding :"Vincular o cartão bancário",
    orderRevenue :"Receita do pedido",
    mySuperior:"Meu superior",
    numberOfSubordinates:"Número de subordinados",
    totalRevenue:"Receita total",
    distributionRevenue:"Receita de distribuição",
    nothing:"Nada",
    recharge:"Recarregar",
    rechargeAddress:"Endereço de recarga",
    copyAddress:"Copiar endereço",
    copySuccess:"Cópia com sucesso",
    copyError:"Falha na cópia",
    myRecommendedLink:"Meu link recomendado",
    exchange:"Cambio",
    loginAndReg:"Login/Cadastro",
    hotRoBot:"Robô popular ",
    rechargePoints:"Pontos de recarga",
    distributionPoints:"pontos de distribuição",
    withdrawal:"Saque",
    pleaseChooseMethod:"Por favor escolha o método",
    rechargeNetwork:"Rede de recarga",
    depositTips:"Apenas depósitos neste endereço {nome} são suportados. Por favor, não deposite outros ativos.",
    minRechargeNum:"Depósito mínimo: {num}",
    maxRechargeNum:"Depósito máximo: {num}",
    rechargeNum:"valor de depósito",
    robot:"robô",
    singleQuestionTime:"Tempo de pergunta único",
    submittedSuccessfully:"Enviado com sucesso",
    account:"Conta",
    creditScore:"Pontuação de crédito",
    accountInformation:"Informações da conta",
    notice:"Aviso",
    onlineService:"Atendimento ao Cliente Online",
    loginIP:"IP de login",
    loginTime:"Hora de login",
    rechargeRecords:"Histórico de Recarga  ",
    noContent:"Nenhum conteúdo ainda",
    pendingReview:"Revisão pendente",
    pleaseCompleteTheForm:"Por favor preencha o formulário",
    distributionRecords:"Registros de Distribuição",
    gettingPointsByAnsweringQuestions:"Ganhe pontos respondendo perguntas",
    yourOpinion:"Sua opinião",
    yourFuture:"Seu futuro",
    joinNow:"Inscreva-se agora",
    operatingPrinciple:"Princípio Operacional",
    verificationCode:"Código de verificação",
    buyRobot:"Comprar robô",
    confirmPurchase:"Confirmar compra",
    mineRobot:"Meu robô",
    expirationTime:"Tempo de expiração",
    running:"Em andamento",
    expired:"Expirado",
    forgotPassword:"Esqueceu a senha",
    dailyProfit:"Lucro Diário",
    dailyMaximum:"Número máximo de perguntas por dia",
    min:"minutos",
    insufficientLevelGoAndUpgradeIt:"Nível insuficiente, precisa atualizar!",
    monthlyProfit:"Lucro Mensal",
    workingHours:"Horário de trabalho",
    hour24:"24Horas",
    gradeRules:"Regras de nivel",
    total:"`Total：",
    myOrder:"Meu pedido",
    pendingProcessing:"Pendente",
    itemInTotal:"Total {num} itens",
    flashExchangeProducts:"Cambio de produto rapido",
    flashRefusal:"Recuso rapido ",
    invitation:"Código de convite",
    emailCodeCantBeEmpty:"O código de verificação de e-mail não pode estar vazio",
    getEmailCode:"Obter código de verificação de e-mail",
    second:"Segundo ",
    myTeam:"Meu time",
    accumulatedTeamSize:"Número cumulativo do time ",
    viewTeamList:"Ver lista do time",
    accumulatedRevenue:"receita acumulada",
    accumulatedRecharge:"Deposito acumulada",
    accumulatedWithdrawal:"Saque acumulada",
    rebatesForFirstGenerationMembers:"Reembolso para membros da primeira geração",
    rebatesForSecondGenerationMembers:"Reembolso para membros da segunda geração",
    thirdGenerationMemberRebate:"Reembolso para membros da terceira geração",
    teamDataOverview:"Visão geral dos dados da equipe",
    lastThreeGenerationsOfData:"Dados das últimas três gerações",
    firstGenerationData:"Dados da primeira geração",
    secondGenerationData:"Dados de segunda geração",
    thirdGenerationData:"Dados de quarta geração",
    rebateRatio:"Taxa de cashback ",
    teamSize:"Tamanho do time",
    problemCountIsMax:"O limite de respostas foi atingido",
    rechargeFlow:"Fluxo de deposito",
    teamCommission:"Comissão de equipe",
    inviteToEarnPoints:"Convide para ganhar pontos",
    thereNothingMoreLeft:"Não tem mais ",
    reset:"redefinir",
    firstGeneration:"LEV1",
    secondGeneration:"LEV2",
    thirdGeneration:"LEV3",
    idTitle:"ID：{id}",
    gradeInviteNum:"Convidar {num} membros subordinados da equipe",
    gradeAnswersNum:"Responda a {num} perguntas do questionário",
    gradeInvitingPoints:"(Convide uma pessoa para obter {num} pontos de distribuição)",
    nowGrade:"Nivel atual",
    emailCodeError:"O erro de código",
    determine:"Confirmar",
    cancel:"Cancelar",
    copy: "Copiar",
    inviteFriendsToRegister: "Convidar amigos para se registrar",
    todayProfit: "Lucro de hoje: {num}",
    taskStatus: "Estado da tarefa: {text}",
    problemCountIsDayMax: "Limite máximo de respostas atingido hoje"

};
