export default {
    username:"Nombre de usuario",
    phone:"Número de teléfono móvil",
    password:"Contraseña",
    email:"Correo electrónico",
    login:"Iniciar sesión",
    register:"Registrarse",
    invitation_code:"Código de invitación: {code}",
    repeat_password:"Repetir contraseña",
    please_fill_in:"Por favor, complete",
    home:"Pagina principal",
    price:"Precio ",
    chart:"Transacción",
    finance:"Activos",
    mine:"Mío",
    deposit:"Depósito",
    withdraw_money:"Retirar dinero",
    contact_us:"Contáctanos",
    market_situation:"Situación del mercado",
    risingList:"Lista de ganadores",
    declineList:"Lista de perdedores ",
    submitting:"Enviando...",
    regularFinancialManagement:"Gestión financiera regular",
    oneStopFinancialManagementService:"Servicio integral de gestión financiera",
    product:"Producto",
    submit:"Enviar",
    latestPrice:"Último precio",
    TodayUpsAndDowns:"Altibajos de hoy",
    phoneReg:"Registro de teléfono móvil",
    emailReg:"Registro por correo electrónico",
    userReg:"Registro de usuario",
    phoneLogin:"Inicio de sesión desde teléfono móvil",
    emailLogin:"Inicio de sesión por correo electrónico",
    userLogin:"Inicio de sesión de usuario",
    retrievePassword:"Recuperar contraseña",
    registerAccount:"Registrar Cuenta",
    tall:"Alto",
    low:"Bajo",
    min_1:"1minuto",
    min_5:"5minutos",
    min_15:"15minutos",
    min_30:"30minutos",
    min_60:"60minutos",
    hour_4:"4horas",
    day_1:"1día",
    buy_up:"compra larga ",
    buy_down:"compra corta",
    dataIsBeingProcessedPleaseDoNotResubmit:"Los datos se están procesando, no los vuelva a enviar",
    usernameCannotBeEmpty:"El nombre de usuario no puede estar vacío",
    accountCannotBeEmpty:"La cuenta no puede estar vacía",
    passwordCannotBeEmpty:"La contraseña no puede estar vacía",
    inviterCodeCannotBeEmpty:"El código de invitación no puede estar vacío",
    accountCannotBeDuplicate:"La cuenta no se puede duplicar",
    registerSuccess:"Registro exitoso",
    loginSuccess:"Inicio de sesión exitoso",
    logOutSuccess:"Salir exitosamente",
    UserDoesNotExistOrPasswordIsIncorrect:"El nombre de usuario está vacío o la contraseña es incorrecta",
    accountCenter:"Centro de cuentas",
    asset:"Activo",
    totalAccountAssetConversion:"Conversión total de activos de la cuenta",
    newShareAllotment:"Nueva asignación de acciones",
    helpCenter:"Centro de ayuda",
    setup:"Configuración",
    financialLending:"Préstamos financieros",
    accountingChangeRecords:"Registros de cambios de cuenta",
    systemPrompt:"Aviso del sistema",
    loginStatusHasExpiredPleaseLogInAgain:"El estado de inicio de sesión ha caducado, inicie sesión nuevamente",
    selectCycle:"Seleccionar ciclo",
    selectAmount:"Seleccione el monto",
    accountBalance:"Saldo de cuenta: {num} $",
    transactionPairs:"Pares de transacciones",
    direction:"Dirección",
    currentPrice:"Precio actual",
    orderAccount:"Monto del pedido",
    expectedRevenue:"Ingresos esperados",
    pleaseEnterTheAmount:"Por favor ingrese el monto",
    confirmOrderPlacement:"Confirmar pedido",
    insufficientBalance:"Saldo insuficiente",
    toBeSettled:"Para ser resuelto",
    settled:"Ya resuelto ",
    profitAndLoss:"Ganancias y Pérdidas",
    time:"Tiempo",
    checkoutSuccess :"Pedido realizado con exito ",
    invalidOrder :"Pedido no válido",
    remark :"Observación：",
    tradingAndPlacingOrders :"Órdenes comerciales",
    amount :"Cantidad",
    type :"Tipo",
    status :"Estado",
    incomplete :"No completado",
    completed :"Completado",
    rejected :"Rechazado",
    expenditure :"Gasto",
    income :"Ingresos",
    quit :"Salir",
    thirdLevelDistribution :"Distribución de tercer nivel",
    bankCardBinding :"Vinculación de tarjetas bancarias",
    orderRevenue :"Ingresos del pedido",
    mySuperior:"Mi superior",
    numberOfSubordinates:"Número de subordinados",
    totalRevenue:"Ingresos totales",
    distributionRevenue:"Ingresos de distribución",
    nothing:"Nada ",
    recharge:"Recargar",
    rechargeAddress:"Dirección de recarga",
    copyAddress:"Copiar dirección",
    copySuccess:"Copia exitosa",
    copyError:"Error en la copia",
    myRecommendedLink:"Mi link de invitacion",
    exchange:"Intercambio ",
    loginAndReg:"Iniciar sesión/Registrarse",
    hotRoBot:"Robot popular",
    rechargePoints:"Puntos de recarga",
    distributionPoints:"Puntos de distribución",
    withdrawal:"Retirar efectivo",
    pleaseChooseMethod:"Por favor, elija el método",
    rechargeNetwork:"Red de recarga",
    depositTips:"Solo se admiten depósitos en esta dirección {nombre}. No deposite otros activos",
    minRechargeNum:"Recarga mínima: {num}",
    maxRechargeNum:"Recarga máxima: {num}",
    rechargeNum:"Monto de recarga",
    robot:"Robot",
    singleQuestionTime:"Turno único de preguntas",
    submittedSuccessfully:"Enviado con éxito",
    account:"Cuenta",
    creditScore:"Puntuación de crédito",
    accountInformation:"Información de la cuenta",
    notice:"Aviso",
    onlineService:"Servicio al cliente en línea",
    loginIP:"IP de inicio de sesión",
    loginTime:"Hora de inicio de sesión",
    rechargeRecords:"Registro de recarga ",
    noContent:"Aún no hay contenido",
    pendingReview:"Pendiente de revisión",
    pleaseCompleteTheForm:"Por favor complete el formulario",
    distributionRecords:"Registros de distribución",
    gettingPointsByAnsweringQuestions:"Obtén puntos respondiendo preguntas",
    yourOpinion:"Su opinion",
    yourFuture:"Su futuro",
    joinNow:"Únete ahora",
    operatingPrinciple:"Principio operativo",
    verificationCode:"Código de verificación",
    buyRobot:"Comprar robot",
    confirmPurchase:"Confirmar compra",
    mineRobot:"Mi robot",
    expirationTime:"Tiempo de vencimiento",
    running:"En proceso ",
    expired:"Caducado",
    forgotPassword:"Olvidé mi contraseña",
    dailyProfit:"Beneficio diario",
    dailyMaximum:"Número máximo de preguntas por día",
    min:"minutos",
    insufficientLevelGoAndUpgradeIt:"Nivel insuficiente, actualízelo!",
    monthlyProfit:"Beneficio mensual",
    workingHours:"Horas de trabajo",
    hour24:"24Horas",
    gradeRules:"Reglas de calificación",
    total:"Total：",
    myOrder:"Mi pedido",
    pendingProcessing:"Pendiente",
    itemInTotal:"Total {num} productos",
    flashExchangeProducts:"Productos rapido de intercambio",
    flashRefusal:"Rechazo rápido ",
    invitation:"Código de invitación",
    emailCodeCantBeEmpty:"El código de verificación de correo electrónico no puede estar vacío",
    getEmailCode:"Obtener código de verificación de correo electrónico",
    second:"Segundo",
    myTeam:"Mi equipo",
    accumulatedTeamSize:"Tamaño de equipo acumulado",
    viewTeamList:"Ver lista del equipo",
    accumulatedRevenue:"Ingresos acumulados",
    accumulatedRecharge:"Recarga acumulada",
    accumulatedWithdrawal:"Retiro acumulado",
    rebatesForFirstGenerationMembers:"Reembolsos para miembros de primera generación",
    rebatesForSecondGenerationMembers:"Reembolsos para miembros de segunda generación",
    thirdGenerationMemberRebate:"Reembolso para miembros de tercera generación",
    teamDataOverview:"Resumen de datos del equipo",
    lastThreeGenerationsOfData:"Últimas tres generaciones de datos",
    firstGenerationData:"Datos de generación",
    secondGenerationData:"Datos de segunda generación",
    thirdGenerationData:"Datos de tercera generación",
    rebateRatio:"Proporción de reembolso",
    teamSize:"Tamaño del equipo",
    problemCountIsMax:"Se ha alcanzado el límite de respuestas",
    rechargeFlow:"Flujo de recarga",
    teamCommission:"Comisión de equipo",
    inviteToEarnPoints:"Invitar a ganar puntos",
    thereNothingMoreLeft:"No más",
    reset:"Restablecer",
    firstGeneration:"LEV1",
    secondGeneration:"LEV2",
    thirdGeneration:"LEV3",
    idTitle:"ID：{id}",
    gradeInviteNum:"Invitar a {num} miembros subordinados del equipo",
    gradeAnswersNum:"Responder {num} preguntas del cuestionario",
    gradeInvitingPoints:"(Invita a una persona a obtener {num} puntos de distribución)",
    nowGrade:"Calificación actual",
    emailCodeError:"El código incorrecto.",
    determine:"Confirmar",
    cancel:"Cancelar",
    copy: "Copiar",
    inviteFriendsToRegister: "Invitar amigos a registrarse",
    todayProfit: "Beneficio de hoy: {num}",
    taskStatus: "Estado de la tarea: {text}",
    problemCountIsDayMax: "Se ha alcanzado el límite máximo de respuestas hoy"
}
