<template>
  <van-overlay :show="true" >
    <div class="loading">
      <van-loading  />
    </div>
  </van-overlay>


</template>
<script>
export default {
  name: 'LOADING',
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.van-overlay {
  background: #ffffffb3;
}
.loading {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep .van-loading__spinner{
    width: 50px;
    height: 50px;
  }
}
</style>
