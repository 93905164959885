export default {
    username: "اسم المستخدم",
    phone: "رقم الهاتف",
    password: "كلمة المرور",
    email: "البريد الإلكتروني",
    login: "تسجيل الدخول",
    register: "التسجيل",
    invitation_code: "رمز الدعوة: {code}",
    repeat_password: "تأكيد كلمة المرور",
    please_fill_in: "يرجى ملء",
    home: "الصفحة الرئيسية",
    price: "سعر النقاط",
    chart: "الرسم البياني",
    finance: "الأصول",
    mine: "حسابي",
    deposit: "الإيداع",
    withdraw_money: "سحب الأموال",
    contact_us: "اتصل بنا",
    market_situation: "الوضع السوقي",
    risingList: "قائمة الارتفاعات",
    declineList: "قائمة الانخفاضات",
    submitting: "جارٍ التقديم...",
    regularFinancialManagement: "إدارة مالية منتظمة",
    oneStopFinancialManagementService: "خدمة إدارة مالية شاملة",
    product: "المنتج",
    submit: "تقديم",
    latestPrice: "آخر سعر",
    TodayUpsAndDowns: "الارتفاعات والانخفاضات اليومية",
    phoneReg: "تسجيل برقم الهاتف",
    emailReg: "تسجيل بالبريد الإلكتروني",
    userReg: "تسجيل المستخدم",
    phoneLogin: "تسجيل الدخول برقم الهاتف",
    emailLogin: "تسجيل الدخول بالبريد الإلكتروني",
    userLogin: "تسجيل الدخول للمستخدم",
    retrievePassword: "استرجاع كلمة المرور",
    registerAccount: "تسجيل الحساب",
    tall: "طويل",
    low: "قصير",
    min_1: "1 دقيقة",
    min_5: "5 دقائق",
    min_15: "15 دقيقة",
    min_30: "30 دقيقة",
    min_60: "60 دقيقة",
    hour_4: "4 ساعات",
    day_1: "1 يوم",
    buy_up: "شراء ارتفاع",
    buy_down: "شراء انخفاض",
    dataIsBeingProcessedPleaseDoNotResubmit: "يتم معالجة البيانات، يرجى عدم إعادة التقديم",
    usernameCannotBeEmpty: "اسم المستخدم لا يمكن أن يكون فارغاً",
    accountCannotBeEmpty: "الحساب لا يمكن أن يكون فارغاً",
    passwordCannotBeEmpty: "كلمة المرور لا يمكن أن تكون فارغة",
    inviterCodeCannotBeEmpty: "رمز الدعوة لا يمكن أن يكون فارغاً",
    accountCannotBeDuplicate: "لا يمكن تكرار الحساب",
    registerSuccess: "تم التسجيل بنجاح",
    loginSuccess: "تم تسجيل الدخول بنجاح",
    logOutSuccess: "تم تسجيل الخروج بنجاح",
    UserDoesNotExistOrPasswordIsIncorrect: "المستخدم غير موجود أو كلمة المرور غير صحيحة",
    accountCenter: "مركز الحساب",
    asset: "الأصول",
    totalAccountAssetConversion: "إجمالي أصول الحساب",
    newShareAllotment: "تخصيص سهم جديد",
    helpCenter: "مركز المساعدة",
    setup: "الإعداد",
    financialLending: "الإقراض المالي",
    accountingChangeRecords: "سجلات التغيير المحاسبي",
    systemPrompt: "تنبيه النظام",
    loginStatusHasExpiredPleaseLogInAgain: "انتهت صلاحية تسجيل الدخول، يرجى تسجيل الدخول مرة أخرى",
    selectCycle: "اختر الدورة",
    selectAmount: "اختر المبلغ",
    accountBalance: "رصيد الحساب: {num} $",
    transactionPairs: "أزواج التداول",
    direction: "الاتجاه",
    currentPrice: "السعر الحالي",
    orderAccount: "مبلغ الطلب",
    expectedRevenue: "العائد المتوقع",
    pleaseEnterTheAmount: "يرجى إدخال المبلغ",
    confirmOrderPlacement: "تأكيد وضع الطلب",
    insufficientBalance: "الرصيد غير كافٍ",
    toBeSettled: "قيد التسوية",
    settled: "مسوَّى",
    profitAndLoss: "الربح والخسارة",
    time: "الوقت",
    checkoutSuccess: "تم الطلب بنجاح",
    invalidOrder: "طلب غير صالح",
    remark: "ملاحظة:",
    tradingAndPlacingOrders: "التداول ووضع الطلبات",
    amount: "الكمية",
    type: "النوع",
    status: "الحالة",
    incomplete: "غير مكتمل",
    completed: "مكتمل",
    rejected: "مرفوض",
    expenditure: "النفقات",
    income: "الدخل",
    quit: "الخروج",
    thirdLevelDistribution: "التوزيع من الدرجة الثالثة",
    bankCardBinding: "ربط بطاقة البنك",
    orderRevenue: "إيرادات الطلب",
    mySuperior: "رئيسي",
    numberOfSubordinates: "عدد الأرقام الصغيرة",
    totalRevenue: "إجمالي الإيرادات",
    distributionRevenue: "إيرادات التوزيع",
    nothing: "لا شيء",
    recharge: "شحن",
    rechargeAddress: "عنوان الشحن",
    copyAddress: "نسخ العنوان",
    copySuccess: "تم النسخ بنجاح",
    copyError: "فشل النسخ",
    myRecommendedLink: "رابط التوصية الخاص بي",
    exchange: "التبادل السريع",
    loginAndReg: "تسجيل الدخول / التسجيل",
    hotRoBot: "الروبوتات الشائعة",
    rechargePoints: "شحن النقاط",
    distributionPoints: "نقاط التوزيع",
    withdrawal: "السحب",
    pleaseChooseMethod: "يرجى اختيار الطريقة",
    rechargeNetwork: "شحن الشبكة",
    depositTips: "يدعم فقط شحن {name} إلى هذا العنوان، يرجى عدم شحن أصول أخرى.",
    minRechargeNum: "الحد الأدنى للشحن: {num}",
    maxRechargeNum: "الحد الأقصى للشحن: {num}",
    rechargeNum: "كمية الشحن",
    robot: "الروبوت",
    singleQuestionTime: "زمن السؤال الواحد",
    submittedSuccessfully: "تم التقديم بنجاح",
    account: "الحساب",
    creditScore: "التصنيف الائتماني",
    accountInformation: "معلومات الحساب",
    notice: "إشعار",
    onlineService: "خدمة العملاء عبر الإنترنت",
    loginIP: "عنوان IP للدخول",
    loginTime: "زمن الدخول",
    rechargeRecords: "سجلات الشحن",
    noContent: "لا توجد محتويات",
    pendingReview: "قيد المراجعة",
    pleaseCompleteTheForm: "يرجى إكمال النموذج",
    distributionRecords: "سجلات التوزيع",
    gettingPointsByAnsweringQuestions: "الحصول على النقاط من خلال الإجابة على الأسئلة",
    yourOpinion: "رأيك",
    yourFuture: "مستقبلك",
    joinNow: "انضم الآن",
    operatingPrinciple: "مبدأ التشغيل",
    verificationCode: "رمز التحقق",
    buyRobot: "شراء الروبوت",
    confirmPurchase: "تأكيد الشراء",
    mineRobot: "الروبوتات الخاصة بي",
    expirationTime: "وقت الانتهاء",
    running: "قيد التشغيل",
    expired: "منتهي الصلاحية",
    forgotPassword: "نسيت كلمة المرور",
    dailyProfit: "الربح اليومي",
    dailyMaximum: "أقصى عدد يومي للأسئلة",
    min: "دقيقة",
    insufficientLevelGoAndUpgradeIt: "المستوى غير كاف، اذهب وقم بترقيته!",
    monthlyProfit: "الربح الشهري",
    workingHours: "ساعات العمل",
    hour24: "24 ساعة",
    gradeRules: "قواعد التصنيف",
    total: "الإجمالي:",
    myOrder: "طلباتي",
    pendingProcessing: "قيد المعالجة",
    itemInTotal: "إجمالي {num} عنصر",
    flashExchangeProducts: "منتجات التبادل السريع",
    flashRefusal: "رفض التبادل السريع",
    invitation: "رمز الدعوة",
    emailCodeCantBeEmpty: "رمز التحقق من البريد الإلكتروني لا يمكن أن يكون فارغًا",
    getEmailCode: "الحصول على رمز البريد الإلكتروني",
    second: "ثانية",
    myTeam: "فريقي",
    accumulatedTeamSize: "حجم الفريق المتراكم",
    viewTeamList: "عرض قائمة الفريق",
    accumulatedRevenue: "الإيرادات المتراكمة",
    accumulatedRecharge: "الشحن المتراكم",
    accumulatedWithdrawal: "السحب المتراكم",
    rebatesForFirstGenerationMembers: "العائد لأعضاء الجيل الأول",
    rebatesForSecondGenerationMembers: "العائد لأعضاء الجيل الثاني",
    thirdGenerationMemberRebate: "العائد لأعضاء الجيل الثالث",
    teamDataOverview: "نظرة عامة على بيانات الفريق",
    lastThreeGenerationsOfData: "بيانات آخر ثلاثة أجيال",
    firstGenerationData: "بيانات الجيل الأول",
    secondGenerationData: "بيانات الجيل الثاني",
    thirdGenerationData: "بيانات الجيل الثالث",
    rebateRatio: "نسبة العائد",
    teamSize: "حجم الفريق",
    problemCountIsMax: "تم الوصول إلى الحد الأقصى لعدد الأسئلة",
    rechargeFlow: "تدفق الشحن",
    teamCommission: "عمولة الفريق",
    inviteToEarnPoints: "الدعوة لكسب النقاط",
    thereNothingMoreLeft: "لا يوجد شيء أكثر",
    reset: "إعادة تعيين",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "الهوية: {id}",
    gradeInviteNum: "دعوة {num} من أعضاء الفريق الفرعي",
    gradeAnswersNum: "إجابة {num} سؤال الاستبيان",
    gradeInvitingPoints: "(دعوة شخص واحد يحصل على {num} نقطة توزيع)",
    nowGrade: "المستوى الحالي",
    emailCodeError:"خطأ في رمز التحقق",
    determine:"تأكيد",
    cancel:"إلغاء",
    copy: "نسخ",
    inviteFriendsToRegister: "دعوة الأصدقاء للتسجيل",
    todayProfit: "الربح اليومي: {num}",
    taskStatus: "حالة المهمة: {text}",
    problemCountIsDayMax: "تم الوصول إلى الحد الأقصى للإجابات اليومية"

};
