export default {
    username: "Имя пользователя",
    phone: "Номер телефона",
    password: "Пароль",
    email: "Электронная почта",
    login: "Войти",
    register: "Регистрация",
    invitation_code: "Пригласительный код: {code}",
    repeat_password: "Повторите пароль",
    please_fill_in: "Пожалуйста, заполните",
    home: "Главная",
    price: "Цена баллов",
    chart: "График",
    finance: "Финансы",
    mine: "Личный кабинет",
    deposit: "Депозит",
    withdraw_money: "Вывод средств",
    contact_us: "Связаться с нами",
    market_situation: "Рыночная ситуация",
    risingList: "Список роста",
    declineList: "Список падения",
    submitting: "Отправка...",
    regularFinancialManagement: "Регулярное финансовое управление",
    oneStopFinancialManagementService: "Услуги все в одном финансовом управлении",
    product: "Продукт",
    submit: "Отправить",
    latestPrice: "Последняя цена",
    TodayUpsAndDowns: "Сегодняшние изменения",
    phoneReg: "Регистрация по телефону",
    emailReg: "Регистрация по электронной почте",
    userReg: "Регистрация пользователя",
    phoneLogin: "Вход по телефону",
    emailLogin: "Вход по электронной почте",
    userLogin: "Вход пользователя",
    retrievePassword: "Восстановление пароля",
    registerAccount: "Регистрация аккаунта",
    tall: "Высокий",
    low: "Низкий",
    min_1: "1 минута",
    min_5: "5 минут",
    min_15: "15 минут",
    min_30: "30 минут",
    min_60: "60 минут",
    hour_4: "4 часа",
    day_1: "1 день",
    buy_up: "Покупка повышения",
    buy_down: "Покупка понижения",
    dataIsBeingProcessedPleaseDoNotResubmit: "Данные обрабатываются, пожалуйста, не отправляйте их повторно",
    usernameCannotBeEmpty: "Имя пользователя не может быть пустым",
    accountCannotBeEmpty: "Аккаунт не может быть пустым",
    passwordCannotBeEmpty: "Пароль не может быть пустым",
    inviterCodeCannotBeEmpty: "Пригласительный код не может быть пустым",
    accountCannotBeDuplicate: "Аккаунт не может быть дублирован",
    registerSuccess: "Регистрация прошла успешно",
    loginSuccess: "Вход выполнен успешно",
    logOutSuccess: "Выход выполнен успешно",
    UserDoesNotExistOrPasswordIsIncorrect: "Пользователь не существует или пароль неверный",
    accountCenter: "Личный кабинет",
    asset: "Активы",
    totalAccountAssetConversion: "Общая стоимость активов",
    newShareAllotment: "Новое размещение акций",
    helpCenter: "Центр помощи",
    setup: "Настройка",
    financialLending: "Финансовое кредитование",
    accountingChangeRecords: "Записи об изменениях в учете",
    systemPrompt: "Системное сообщение",
    loginStatusHasExpiredPleaseLogInAgain: "Статус входа истек, пожалуйста, выполните вход снова",
    selectCycle: "Выберите цикл",
    selectAmount: "Выберите сумму",
    accountBalance: "Баланс аккаунта: {num} $",
    transactionPairs: "Пары транзакций",
    direction: "Направление",
    currentPrice: "Текущая цена",
    orderAccount: "Сумма заказа",
    expectedRevenue: "Ожидаемый доход",
    pleaseEnterTheAmount: "Пожалуйста, введите сумму",
    confirmOrderPlacement: "Подтвердить размещение заказа",
    insufficientBalance: "Недостаточный баланс",
    toBeSettled: "На рассмотрении",
    settled: "Рассмотрено",
    profitAndLoss: "Прибыль и убыток",
    time: "Время",
    checkoutSuccess: "Операция выполнена успешно",
    invalidOrder: "Неверный заказ",
    remark: "Примечание:",
    tradingAndPlacingOrders: "Торговля и размещение заказов",
    amount: "Количество",
    type: "Тип",
    status: "Статус",
    incomplete: "Не завершено",
    completed: "Завершено",
    rejected: "Отклонено",
    expenditure: "Расход",
    income: "Доход",
    quit: "Выход",
    thirdLevelDistribution: "Трехуровневое распределение",
    bankCardBinding: "Привязка банковской карты",
    orderRevenue: "Доход от заказа",
    mySuperior: "Мой вышестоящий",
    numberOfSubordinates: "Количество подчиненных",
    totalRevenue: "Общий доход",
    distributionRevenue: "Доход от распределения",
    nothing: "Ничего",
    recharge: "Пополнение",
    rechargeAddress: "Адрес для пополнения",
    copyAddress: "Скопировать адрес",
    copySuccess: "Скопировано успешно",
    copyError: "Ошибка копирования",
    myRecommendedLink: "Моя рекомендованная ссылка",
    exchange: "Обмен",
    loginAndReg: "Вход/Регистрация",
    hotRoBot: "Популярные роботы",
    rechargePoints: "Пополнение баллов",
    distributionPoints: "Распределение баллов",
    withdrawal: "Вывод",
    pleaseChooseMethod: "Выберите метод",
    rechargeNetwork: "Сеть пополнения",
    depositTips: "Поддерживается только пополнение {name}. Не пополняйте другие активы.",
    minRechargeNum: "Минимальное пополнение: {num}",
    maxRechargeNum: "Максимальное пополнение: {num}",
    rechargeNum: "Сумма пополнения",
    robot: "Робот",
    singleQuestionTime: "Время на один вопрос",
    submittedSuccessfully: "Успешно отправлено",
    account: "Аккаунт",
    creditScore: "Кредитный рейтинг",
    accountInformation: "Информация об аккаунте",
    notice: "Уведомление",
    onlineService: "Онлайн сервис",
    loginIP: "IP адрес входа",
    loginTime: "Время входа",
    rechargeRecords: "Записи о пополнении",
    noContent: "Нет содержимого",
    pendingReview: "Ожидает рассмотрения",
    pleaseCompleteTheForm: "Пожалуйста, заполните форму",
    distributionRecords: "Записи о распределении",
    gettingPointsByAnsweringQuestions: "Получение баллов за ответы на вопросы",
    yourOpinion: "Ваше мнение",
    yourFuture: "Ваше будущее",
    joinNow: "Присоединиться сейчас",
    operatingPrinciple: "Принцип работы",
    verificationCode: "Код подтверждения",
    buyRobot: "Покупка робота",
    confirmPurchase: "Подтвердить покупку",
    mineRobot: "Мои роботы",
    expirationTime: "Срок действия истек",
    running: "Работает",
    expired: "Истек срок",
    forgotPassword: "Забыли пароль",
    dailyProfit: "Ежедневная прибыль",
    dailyMaximum: "Максимальное количество вопросов в день",
    min: "Минута",
    insufficientLevelGoAndUpgradeIt: "Недостаточный уровень. Поднимите его!",
    monthlyProfit: "Ежемесячная прибыль",
    workingHours: "Рабочие часы",
    hour24: "24 часа",
    gradeRules: "Правила ранга",
    total: "Всего:",
    myOrder: "Мои заказы",
    pendingProcessing: "Ожидает обработки",
    itemInTotal: "Всего товаров: {num}",
    flashExchangeProducts: "Товары для быстрого обмена",
    flashRefusal: "Отказ от быстрого обмена",
    invitation: "Приглашение",
    emailCodeCantBeEmpty: "Код электронной почты не может быть пустым",
    getEmailCode: "Получить код электронной почты",
    second: "секунда",
    myTeam: "Моя команда",
    accumulatedTeamSize: "Накопленный размер команды",
    viewTeamList: "Просмотр списка команды",
    accumulatedRevenue: "Накопленный доход",
    accumulatedRecharge: "Накопленное пополнение",
    accumulatedWithdrawal: "Накопленный вывод",
    rebatesForFirstGenerationMembers: "Рибейты для участников первого поколения",
    rebatesForSecondGenerationMembers: "Рибейты для участников второго поколения",
    thirdGenerationMemberRebate: "Рибейты для участников третьего поколения",
    teamDataOverview: "Обзор данных команды",
    lastThreeGenerationsOfData: "Данные последних трех поколений",
    firstGenerationData: "Данные первого поколения",
    secondGenerationData: "Данные второго поколения",
    thirdGenerationData: "Данные третьего поколения",
    rebateRatio: "Коэффициент рибейтов",
    teamSize: "Размер команды",
    problemCountIsMax: "Достигнут максимальный предел вопросов",
    rechargeFlow: "Поток пополнения",
    teamCommission: "Комиссия за команду",
    inviteToEarnPoints: "Приглашение для заработка баллов",
    thereNothingMoreLeft: "Больше ничего не осталось",
    reset: "Сброс",
    firstGeneration: "LEV1",
    secondGeneration: "LEV2",
    thirdGeneration: "LEV3",
    idTitle: "ID: {id}",
    gradeInviteNum: "Пригласить {num} членов команды",
    gradeAnswersNum: "Ответить на {num} вопросов в опроснике",
    gradeInvitingPoints: "(За приглашение одного человека получить {num} баллов)",
    nowGrade: "Текущий уровень",
    emailCodeError:"Неверный код подтверждения",
    determine:"Подтвердить",
    cancel:"Отменить",
    copy: "Копировать",
    inviteFriendsToRegister: "Пригласить друзей зарегистрироваться",
    todayProfit: "Прибыль сегодня: {num}",
    taskStatus: "Статус задачи: {text}",
    problemCountIsDayMax: "Достигнут предел ответов на сегодня"


};
